body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #b71c1c;
  --ligth-color: #f05545;
  --dark-color: #7f0000;
  --text-color: #ffffff;
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
}
a.dropdown-toggle::after {
  display: none;
}
.loading-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000;
}
.loading-item {
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  margin: 5px;
  animation: fade 1s infinite;
}
.video-modal {
  position: fixed;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  transition: all .5s;
}
.video-modal video {
  width: 80%;
}
.show-video-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
.video-modal-close {
  position: absolute;
  top:10px;
  right: 20px;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.layout-padding-1 div {
  padding: 10px;
}
.btn-style {
  color: var(--text-color);
}

.btn-style:hover {
  color: var(--ligth-color);
}

.hide-caret:after {
  content: none;
}
.mt-1 {
  margin-top: 1rem;
}

.color-white {
  color: white;
}
.row-style {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.icon-bar {
  cursor: pointer;
  color: #37474f;
}

.icon-bar:hover {
  color: #62727b;
}

.color-nav {
  /* background-color: var(--primary-color); */
  height: 3rem;
}
.navbar-menu,
.nav-center,
.nav-end {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-center {
  width: 70%;
  justify-content: left;
}

.nav-end {
  width: 22%;
}
.nav-end * {
  display: flex;
  justify-content: flex-end;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar button:hover {
  background: #e1e1e1;
}
.react-calendar__month-view__days__day--weekend {
  color: red;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #90a4ae;
}

.react-calendar__tile--now::before {
  content: "";
  position: absolute;
  border: solid thin;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: var(--primary-color);
  z-index: -1;
}

.react-calendar__tile--now {
  color: #ffffff;
}

.react-calendar button {
  background: none;
  border: solid thin #e1e1e1;
  padding: 20px;
}

@media only screen and (max-width: 780px) {
  .table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }
  .modal {
    width: 100vw;
    height: 100vh;
  }
  .card {
    width: 100%;
  }
  .nav-center {
    display: none;
  }
  .nav-end {
    width: 90%;
  }
  .nav-start {
    width: 5%;
    justify-content: flex-start;
  }
  .user-name {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
